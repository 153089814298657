import * as React from "react"
import { Link } from "gatsby"
import Logo from "./../assets/svg/365BOX-Icon-Inverted.svg"
import { ReactSVG } from "react-svg"

const Header = () => (
  <div className="bg-primary w-full z-50  fixed top-0">
    <header className=" max-w-screen-xl mx-auto flex justify-beetween w-full px-8 md:px-16 py-4">
      <div className="inline-block">
        <Link to={"/"} className=" w-12  h-12">
          <ReactSVG src={Logo} />
        </Link>
      </div>
      <div className="flex flex-row gap-4 ml-auto items-center">
        <Link className="font-bold text-background" to={"/blog"}>
          Blog
        </Link>
        <Link className="font-bold text-background" to={"/video"}>
          Videos
        </Link>
        <Link className="font-bold text-background bg-highlight py-1 px-2"
          to={"/demo"}>
          Demo Buchen
        </Link>
      </div>
    </header>
  </div>
)

export default Header
