import { Link } from "gatsby"
import React from "react"
import { ReactSVG } from "react-svg"
import FooterLogo from "./../assets/svg/365BOX-logo-footer.svg"
import LinkedIn from "./../assets/svg/linkedin.svg"

import Youtube from "./../assets/svg/youtube.svg"

const Footer = () => {
  return (
    <div className="bg-primary">
      <div className=" flex py-8 px-8 max-w-screen-xl mx-auto">
        <div className="space-y-2">
          <div>
            <img alt="" src={FooterLogo} />
          </div>
          <div className="text-background text-xs font-bold">
            {"© 2022 · Alle rechte vorbehalten"}
          </div>
          <div className="space-x-4">
          <Link className="text-background text-xs font-bold" to={"/impressum"}>
              {"Impressum"}
            </Link>
            <Link className="text-background text-xs font-bold" to={"/privacy"}>
              {"Datenschutz"}
            </Link>
          </div>
        </div>
        <div className="ml-auto flex flex-col gap-4 justify-end py-2">
          <div className="flex gap-2">
            <Link to={"https://www.linkedin.com/company/365box"} target={"_blank"} >
              <ReactSVG className="h-4 w-4" src={LinkedIn} />
            </Link>
            <Link to={"https://www.youtube.com/channel/UCD9ZMsaTGWikSfKN9XD4YBw"} target={"_blank"}>
              <ReactSVG className="h-4 w-4" src={Youtube} />
            </Link>
          </div>
          <Link to={"/"} className="text-background">
            {"365BOX.DE"}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Footer
